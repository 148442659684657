import styled from "styled-components";
import React, { useEffect } from "react";
import { BottomNavSegment } from "../components/PageSegments/BottomNav";
import { BasicLayout } from "../layouts/BasicLayout";
import SpecialOfferModal from "../components/Modal/SpecialOfferModal";
import {
  SpecialOfferBar,
  HeroSegment,
  AppPitchSegment,
  TestimonySegment,
} from "../components/PageSegments/special-offer/landing";
import { useOnboardingContext } from "../components/PageSegments/onboarding/OnboardingContext";
import { web_acq_landing_page_view } from "../services/mixpanel/acquisition-events";
import { fbq } from "../services/pixel";
import { setMixpanelProperties } from "../services/mixpanel/mixpanel";
import { REMOTE_CONFIG_STRING_KEYS } from "../services/firebase-remote-config";
import { TopBanner } from "../components/ui/TopBanner";
import { mobileSize } from "../util/variables";
import { HeroSection } from "../components/PageSegments/special-offer/landing/variation/HeroSection";
import TestimonialSection from "../components/PageSegments/special-offer/landing/variation/TestimonialSection";
import { AppPitchSection } from "../components/PageSegments/special-offer/landing/variation/AppPitchSection";
import CoursesSection from "../components/PageSegments/special-offer/landing/variation/CoursesSection";
import { Link } from "gatsby";
import useRemoteConfig from "../hooks/useRemoteConfig";

const TEST_KEY = REMOTE_CONFIG_STRING_KEYS.landingPageVariation;

export default (data: any) => {
  const { setOnboardingLandingPage } = useOnboardingContext();
  const urlParams = new URLSearchParams(data.location.search);
  const fromParam = urlParams.get("from") ?? undefined;
  const [variant, useDefault] = useRemoteConfig(TEST_KEY);

  useEffect(() => {
    if (variant === "A" || variant === "B") {
      console.debug("Welcome variation", variant);
      setMixpanelProperties(
        { landingPageVariation: variant === "A" ? "control" : "newAnimation" },
        true,
      );
    }
  }, [variant]);

  useEffect(() => {
    web_acq_landing_page_view();
    fbq("trackCustom", "landing_page_view");
    setOnboardingLandingPage("web_discount_flow");
  }, [setOnboardingLandingPage, fromParam]);

  if (variant !== "A" && variant !== "B" && !useDefault) {
    return null;
  }

  return (
    <BasicLayout themeColor={variant === "A" ? undefined : "#FFFFFF"}>
      <Main>
        {
          // eslint-disable-next-line no-negated-condition
          variant !== "B" ? (
            <>
              <SpecialOfferBar />
              <HeroSegment />
              <TestimonySegment />
              <AppPitchSegment />
              <SpecialOfferModal />
            </>
          ) : (
            <>
              <TopBanner fixed={false}>
                <BannerText>
                  🏆 Winner of Google&apos;s <b>App of the Year!</b>
                  <MobileBr />{" "}Celebrate with{" "}
                  <Link to="/onboarding/questions">20% off.</Link>
                </BannerText>
              </TopBanner>
              <HeroSection />
              <TestimonialSection />
              <AppPitchSection />
              <CoursesSection />
            </>
          )
        }
      </Main>
      <BottomNavSegment />
    </BasicLayout>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const BannerText = styled.p`
  margin: 0;
  line-height: 1.25;
  @media ${mobileSize} {
    font-size: 20px;
  }
`;

const MobileBr = styled.br`
  display: none;
  @media ${mobileSize} {
    display: block;
  }
`;
